$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';

@include up-to-desktop {
  body.drawer-open {
    overflow: hidden !important; // Stops vertical scroll of background when drawer is open.
    position: fixed; // Stops vertical scroll on iOS.
  }
}

#mobileMenuButton {
  display: none;
  align-items: center;
  color: $primary;
  margin-right: 0;
  text-decoration: none;
  font-weight: 600;
  border-bottom: none;

  .md-icon {
    margin: 0 -2px 0 $gap-small;
    font-size: rem(30px);
    color: $primary !important;
  }

  @include up-to-desktop {
    display: flex !important;
  }

  @include tsb {
    .md-icon {
      color: $white !important;
    }
  }
}

.md-overlay.md-overlay--drawer {
  background-color: $primary-transparent;
  display: none;

  @include up-to-desktop {
    display: block;
  }
}

#mobileMenuDrawer {
  max-width: calc(100vw - 60px) !important;
  width: 320px !important;
  left: auto !important;
  background-color: $primary;
  display: none;
  flex-direction: column;
  padding: $gap-large $gap-normal $gap-large $gap-xlarge;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  a {
    text-decoration: none;
    border-bottom: none;
    &:after {
      content: none;
    }
    &:hover span,
    &:active span {
      text-decoration: underline;
    }

    &:not([href]) {
      pointer-events: none;
      user-select: none;
      opacity: 0.4;
    }
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $gap-enormous;

    a {
      display: flex;
      align-items: center;
      margin: 0;
      color: $white;
      font-weight: $body-font-weight;

      .md-icon {
        color: $white;
      }
    }

    #mobileMenuLogOutButton,
    #mobileMenuLoginButton {
      color: $yellow;

      @include tsb {
        font-weight: bold;
      }

      .md-icon {
        margin-right: $gap-small;
        color: $yellow;
        font-size: rem(20px);
      }
    }

    #mobileMenuMenuButton {
      font-size: rem(18px);
      .md-icon {
        margin: 0 $gap-small 0 0;
        font-size: rem(30px);
      }
    }

    #mobileMenuCloseButton .md-icon {
      font-size: rem(22px);
    }
  }

  > hr {
    width: 100%;
    height: 2px;
    border: none;
    background-color: $white;
    opacity: 0.2;
    margin: $gap-small 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > a {
    color: white;
    font-size: rem(18px);
    font-weight: $body-font-weight;
    padding: $gap-normal 0;
    display: flex;
    align-items: center;
    outline: none !important;
    user-select: none;

    &.active {
      font-weight: bold;
    }

    .md-icon {
      color: $white;
      font-size: rem(20px);
      margin: 2px $gap-small -2px 0;
    }
  }

  @include up-to-desktop {
    display: block;
  }
}
