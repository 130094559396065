$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  outline: none !important;
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: $md-primary-color;
  animation: spinner 0.6s linear infinite;
}

.spinner-full-page:before {
  position: fixed;
}

.spinner-hide-rest {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
