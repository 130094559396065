$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

#footer {
  width: 100%;
  z-index: 1;
  background: $primary;
  color: $white;
  font-size: rem(14px);

  .footer-content {
    padding: $gap-xlarge $gap-xxlarge;
    max-width: $boxed-width;
    margin: auto;
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;

    // Negative margins + padded children means that children are correctly spaced regardless of how they wrap:
    margin-top: 0;
    margin-right: -$gap-large;
    margin-bottom: -$gap-xxsmall;
    margin-left: -$gap-large;

    .footer-link-container {
      padding: $gap-xxsmall $gap-large;
    }

    a {
      color: $white;
      font-size: rem(14px);
      border-bottom: none;
      white-space: nowrap;

      &:hover,
      &:active {
        color: $white;
        text-decoration: underline;
      }

      &:focus {
        color: $white;
        outline-color: $white;
      }

      &::after {
        content: none;
      }
    }
  }

  @include up-to-desktop {
    .footer-links {
      margin-top: $gap-xsmall;
    }
  }

  @include phone-only {
    font-size: rem(16px);

    .footer-content {
      padding: $gap-xlarge $gap-normal;
    }

    .footer-links {
      margin-top: $gap-normal;
      margin-right: -$gap-normal;
      margin-bottom: -$gap-xsmall;
      margin-left: -$gap-normal;

      .footer-link-container {
        padding: $gap-xsmall $gap-normal;
      }

      a {
        font-size: rem(16px);
      }
    }
  }
}
