$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

#Covid {

  .list{
    li {
      margin-top: $gap-small;
      padding-left: 0;
    }
  }
  .heading-wrapper {
    position: relative;
    color: white;
    width: 100vw;
    position: relative;
    padding: 40px 0px 50px 0px;
    @include desktop {
      padding: 60px 0px 80px 0px;
    }

    &::before {
      content: '';
      background: $primary;
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      height: 100%;
      top: 0px;
    }

    h2 {
      color: white;
      font-weight: 400;
      margin-left: 8px;
      position: relative;
      @include desktop {
        margin-left: 115px;
      }
    }

    .custom-button {
      position: relative;
      background: $secondary;
      padding: $gap-small $gap-large;
      text-align: center;
      color: white;
      text-decoration: none;
      border: none;
      border-radius: 4px;
      margin-top: 10px;
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;
      @include desktop {
        margin-left: 120px;
      }
    }
  }
  .covid-form {
    overflow: hidden;
    margin-top: 40px;
    padding-bottom: 80px;
    @include desktop {
      margin-left: 105px;
      margin-right: 105px;
      padding-bottom: 120px;
    }

    > div {
      position: relative;
    }
  }

  .main > .container,
  .main > .container > .md-cell {
    padding-top: 0px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .page-heading2 {
    margin-bottom: 25px;
  }

  .question {
    margin-left: 0px;
  }

  #ErrorMessage {
    max-width: $question-width;
    margin-left: 0;

    @include desktop {
      margin-top: -16px;
    }
  }
  #questionPaymentBankAccountNumber .bank-account-field-wrapper {
    overflow: hidden;
  }

  #Success {
    padding-bottom: 400px;
    p {
      margin: $gap-large 0px;
    }
    a {
      margin-top: $gap-xxenormous;
    }
  }
}
