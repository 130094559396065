$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.header-link {
  display: flex;
  align-items: center;
  color: $md-secondary-color;
  font-weight: 600;
  text-decoration: none !important;
  border-bottom: none !important;

  span {
    margin: 0 $gap-xsmall;
  }

  &:hover span,
  &:active span {
    text-decoration: underline;
  }

  &:focus {
    outline-offset: 2px;
  }

  &:not(:last-child) {
    margin-right: $gap-large;
  }

  i {
    color: inherit !important;
    font-size: rem(22px) !important;
    width: rem(22px) !important;
    height: rem(22px) !important;
    margin-top: -4px;
    margin-bottom: -6px;
    margin-left: 2px;
  }

  i + span {
    margin-left: $gap-xsmall;
  }

  @include tsb {
    color: $white;
  }
}
