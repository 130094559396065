$brand: twr;
.md-numberfield-wrapper.md-textfield-wrapper {
  position: relative;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input {
    text-indent: 12px;
  }
}
