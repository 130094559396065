$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.md-radiobutton {
  &:hover {
    box-shadow: 0px 2px 4px 1px rgba($primary-outline-button-background-color--active, 0.5);
  }

  &.md-btn--outline-primary--active,
  &.active,
  &:active {
    background-color: $primary-radiobutton-background-color--active;
    color: $primary-radiobutton-text-color--active;
    border-color: $primary-radiobutton-border-color--active;
  }
}

.md-radiobutton-container {
  margin-left: -$gap-grid / 2;
  margin-right: -$gap-grid / 2;
  display: flex;
  flex-wrap: wrap;

  .md-radiobutton--wrapper {
    display: flex;
    flex-direction: column;
    margin-top: $gap-grid;
    margin-bottom: 0;
    margin-left: $gap-grid / 2;
    margin-right: $gap-grid / 2;
    width: calc(50% - #{$gap-grid});
    padding-left: 0;
    padding-right: 0;
    font-size: rem(18px);
    &:first-child,
    &:nth-of-type(2) {
      margin-top: 0;
    }
    &:nth-of-type(2) {
      margin-right: 0;
    }

    .md-radiobutton {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      flex-grow: 0;
    }

    .md-btn--outline-text {
      div + div {
        margin-top: $gap-xxsmall;
      }
    }

    p {
      margin-top: $gap-normal;
    }
  }

  .md-radiobutton--wrapper--withdescription {
    .md-radiobutton {
      justify-content: flex-start;
    }

    @include up-to-tablet {
      width: 100%;
      margin-bottom: $gap-large;

      .md-radiobutton {
        justify-content: flex-start;
      }

      p {
        font-size: rem(16px);
        line-height: rem(22px);
      }
    }
  }
  .above-description {
    font-size: rem(16px);
    line-height: rem(22px);
    font-weight: bold;
    margin-top: $gap-normal;
  }
}

.md-radiobutton-container--left-align {
  .md-radiobutton {
    justify-content: flex-start;
    div {
      text-align: left;
    }
  }
}

.md-radiobutton-container--triple {
  @include large-desktop {
    padding-right: 6px;
  }
  .md-radiobutton--wrapper {
    @include phablet {
      width: calc(33.3% - #{$gap-grid});

      &:first-child,
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-top: 0;
      }
      &:nth-of-type(2) {
        margin-right: $gap-grid / 2;
      }
      &:nth-of-type(3) {
        margin-right: 0;
      }
    }
  }
}

.md-radiobutton-container--boxed {
  &.md-radiobutton-container--triple .md-radiobutton--wrapper {
    @include tablet {
      width: calc(33.3% - #{$gap-grid});
      margin-bottom: 0;
      max-width: 298px;
    }
  }
  &.md-radiobutton-container--triple .md-radiobutton--box-link,
  &.md-radiobutton-container--triple .md-radiobutton--boxed-additional-text {
    @include tablet {
      width: 100%;
    }
  }

  .md-radiobutton--wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: $gap-large;
    @include tablet {
      width: calc(50% - #{$gap-grid});
      margin-bottom: 0;
      max-width: 298px;
    }
  }

  .md-radiobutton--box-link {
    margin: $gap-large 0;
    display: none;
    @include tablet {
      width: $question-width;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      margin-left: 5px;
      margin-right: 7px;
    }

    @include desktop {
      margin-right: 7px;
    }

    @include large-desktop {
      margin-right: 0px;
    }

    .expand-link {
      width: 100%;
      text-align: center;
      background-color: $expand-link-bg;
      border: none;
      padding: $gap-small;
      border-radius: 3px;
      outline: none !important;
      border: none !important;
      > span {
        border-bottom: 1px dotted $md-primary-color;
      }
    }

    .expand-link:hover {
      > span {
        border-bottom: transparent;
      }
    }

    .md-icon {
      padding: $gap-xxsmall;
      font-size: rem(20px);
      border-radius: 3px;
      vertical-align: text-top;
    }
  }

  .md-radiobutton--boxed-additional-text {
    margin: $gap-large 0;
    margin-left: $gap-grid / 2;
    margin-right: $gap-grid / 2;
    display: block;
    @include tablet {
      width: $question-width;
      text-align: center;
      display: none;
    }
  }

  &--open .md-radiobutton--boxed-additional-text {
    @include tablet {
      display: block;
    }
  }

  .md-radiobutton--box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @include tablet {
      border: 1px solid $grey;
      border-radius: 3px 3px 0px 0px;
      padding: $gap-normal;
    }

    .md-radiobutton {
      height: auto;
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      // flex-grow: 1;
      padding-left: 16px;
      @include tablet {
        padding-left: 8px;
        min-height: 50px;
        height: 49px;
        padding-top: 0;
        padding-bottom: 0;
      }
      @include large-desktop {
        padding-left: 16px;
      }
    }
    p {
      margin: $gap-small 0 0 0;
      font-weight: $body-font-weight;
      flex-grow: 1;
    }

    .link-with-icon {
      // display: none;
      @include tablet {
        display: flex;
        align-items: center;
      }
      i {
        color: $radio-button-link-color;
        margin-left: $gap-small;
      }
      a:hover + i,
      a:active + i,
      a:focus + i {
        color: $radio-button-link-hover-color;
      }
    }
  }

  .md-radiobutton--box-mobile-content {
    display: block;
    @include tablet {
      display: none;
    }

    .expand-link {
      display: inline-flex;
      align-items: center;
      margin-top: $gap-small;
    }
  }

  .md-radiobutton--box--children {
    height: auto;
    max-height: 0;
    overflow: hidden;
    border-top: none;

    &--open {
      max-height: 100%;
    }

    // @include tablet {
    //   border: 1px solid $grey;
    // }

    ul {
      padding: 0;
      margin-bottom: 0;

      @include tablet {
        padding: 0 $gap-normal;
      }
    }
    ul li {
      list-style: none;
      font-size: rem(16px);
      font-weight: $body-font-weight;
      padding-top: $gap-small;
      padding-bottom: $gap-small;
      padding-right: $gap-xlarge;
      position: relative;
      border-top: 1px solid $grey;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 1px solid $grey;
      }
      @include tablet {
        border: none;
        margin-bottom: $gap-large;
        padding-top: 0;
        padding-bottom: 0;
        &:last-child {
          border: none;
        }
      }

      i.done {
        position: absolute;
        top: 10px;
        right: 0px;
        background: transparent;
        color: $green;
        font-size: rem(24px);
        @include tablet {
          top: 0px;
        }
      }

      i.clear {
        position: absolute;
        top: 10px;
        right: 0px;
        background: transparent;
        color: $red;
        font-size: rem(24px);

        @include tablet {
          top: 0px;
        }
      }
    }

    .link-with-icon {
      display: flex;
      align-items: center;
      border-top: none;
      padding: $gap-small;
      padding-left: 0px;
      margin-bottom: 0;

      @include tablet {
        border-top: 1px solid $grey;
        padding-left: $gap-small;
      }

      a {
        border-bottom: none;
        text-decoration: underline;
      }
      i {
        color: $primary;
        margin-left: $gap-small;
      }
      a:hover,
      a:hover + i.md-icon,
      a:active + i.md-icon,
      a:focus + i.md-icon {
        color: $secondary;
      }
    }
  }

  &--open {
    .md-radiobutton--box--children {
      max-height: 100%;
      @include tablet {
        border: 1px solid $grey;
        border-top: none;
      }
    }
  }

  .md-radiobutton--wrapper--active {
    .link-with-icon {
      a:hover + i,
      a:focus + i,
      a:active + i {
        color: $white;
      }
    }
    @include tablet {
      // background: $grey-light;
      .md-radiobutton--box {
        // background: $primary-radiobutton-background-color--active;
        p,
        a,
        i {
          color: $primary;
        }
      }
      .md-radiobutton--box--children {
        background: $grey-light;

        ul li {
          color: $primary;
        }
      }
      .md-radiobutton {
        // background: $white;
        color: $white;
        i {
          color: $white;
        }
      }
      .link-with-icon a:focus {
        outline-color: $white;
      }
    }
  }
}
