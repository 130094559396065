$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

#questionPaymentBankAccountNumber {
  .bank-account-field-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;

    + p {
      margin-top: $gap-small;
    }
  }
  .md-textfield-wrapper {
    margin-right: $gap-small;
    &:last-child {
      margin-right: 0;
    }
    .error-container {
      display: none !important;
    }
  }
  #txtBankAccountNumberBank-wrapper {
    width: 75px;
  }
  #txtBankAccountNumberBranch-wrapper {
    width: 95px;
  }
  #txtBankAccountNumberAccount-wrapper {
    width: 160px;
  }
  #txtBankAccountNumberSuffix-wrapper {
    width: 75px;
  }
  .spinner {
    position: absolute;
    right: 30px;
    bottom: 45px;
    top: inherit;
    display: none;
    @include tablet {
      left: 465px;
      bottom: 45px;
      right: initial;
    }
    &.visible {
      display: block;
    }
  }
  .tick {
    position: absolute;
    left: 440px;
    top: inherit;
    bottom: 30px;
    color: $green;
  }
  .bankname-container {
    position: relative;
    > p {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .tooltip-icon {
      position: relative;
      height: 30px;
      width: 45px;

      > div > a {
        top: -10px;
        right: 3px;

        @include tablet {
          top: 0px;
        }
      }
    }
  }
}
