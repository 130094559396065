$brand: twr;
@import '~common/styles/variables';
@import '~common/styles/media-queries';
@import '~common/styles/mixins';

.question-tick {
  position: absolute;
  top: 15px;
  margin-top: -20px;
  left: -45px;
  text-align: center;
  padding-top: $gap-normal;
  padding-bottom: $gap-normal;
  padding-left: 3px;
  padding-right: 3px;
  background: white;
  display: flex;
  flex-direction: column;

  @include tablet {
    left: -55px;
    top: 0;
  }

  @include desktop {
    left: -75px;
  }

  .question-tick-icon {
    border: 1px solid $grey-light;
    background: $grey-light;
    transition: all 0.25s ease-in;
    // opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    font-size: rem(14px);

    @include tablet {
      width: 37px;
      height: 37px;
      border-radius: 37px;
      font-size: rem(24px);
    }

    &.question-tick-icon--default {
      color: $white;
    }

    &.question-tick-icon--success {
      background: white;
      color: $green;
    }
    &.question-tick-icon--error {
      background: $grey-light;
      color: $red;
    }
  }

  &--active.question-tick-icon {
    // transition: opacity 0.25s ease-in;
    // opacity: 1;
  }
  .question-tick-number {
    color: $text-grey-small-on-white;
    font-size: rem(13px);
    line-height: rem(14px);
    font-weight: $body-font-weight;
    margin-top: $gap-xsmall;
    &--success {
      color: $primary;
    }
    &--error {
      color: $red;
    }
  }
}
